import ParkCard from './ParkCard';

const ParkDetail = ({ park }) => {
  return (
    <>
      <ParkCard park={park} showCheckIn />
    </>
  );
};

export default ParkDetail;