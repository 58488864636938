
import { useNavigate } from 'react-router-dom';
import Carousel from 'react-material-ui-carousel';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import CardActions from '@mui/material/CardActions';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { CardActionArea } from '@mui/material';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import PhotoSizeSelectActualIcon from '@mui/icons-material/PhotoSizeSelectActual';
import FenceRoundedIcon from '@mui/icons-material/FenceRounded';
import EventSeatRoundedIcon from '@mui/icons-material/EventSeatRounded';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import LightModeIcon from '@mui/icons-material/LightMode';

import PresentDogs from './PresentDogs';


function importAll(r, park) {
  let images = [];

  r.keys().map((item) => {
    if (item.includes(park.slug)) {
      images.push(r(item));
    }
  });

  return images;
}

const ParkCard = ({ park, showCheckIn = false }) => {
  let navigate = useNavigate();
  const images = importAll(require.context('../../assets/img/parks/', true), park);
  const defaultImages = ['https://placedog.net/800/640?id=10', 'https://placedog.net/800/640?id=13', 'https://placedog.net/800/640?id=20', 'https://placedog.net/800/640?id=32', 'https://placedog.net/800/640?id=109', 'https://placedog.net/800/640?id=133', 'https://placedog.net/800/640?id=138'];

  return (
    <Card sx={{ minWidth: '320px' }}>
      <CardActionArea>
        {images.length > 0 &&
          <Carousel
            height="250px"
            indicatorIconButtonProps={{
              style: {
                zIndex: 9999
              }
            }}
            indicatorContainerProps={{
              style: {
                marginBottom: '10px',
              }
            }}
            swipe={true}
            fullHeightHover={true}
          >
            {
              images.map((image) => {
                return <img key={image} src={image} />;
              })
            }
          </Carousel>
        }
        {images.length === 0 &&
          <CardMedia
            component="img"
            height="200"
            image={defaultImages[(Math.random() * defaultImages.length) | 0]}
            alt={park.name}
          />
        }
        <CardContent>
          <Typography gutterBottom variant="h5" component="div" sx={{ textTransform: 'capitalize' }}>
            {park.name}
          </Typography>
          <Typography gutterBottom variant="p" component="p" sx={{ textTransform: 'capitalize' }}>
            {park.street} ,{park.postal} {park.deurne}
          </Typography>
          <Stack direction="row" spacing={1}>
            {park.size && <Chip size="small" icon={<PhotoSizeSelectActualIcon />} label= {`${Math.round(park.size)}m2`} />}
            {park.heightClosure && <Chip size="small" icon={<FenceRoundedIcon />} label= {park.heightClosure} />}
            <Chip size="small" icon={<LightModeIcon />} label= {park.lights} />
            <Chip size="small" icon={<EventSeatRoundedIcon />} label= {park.benches} />
            <Chip size="small" icon={<DeleteRoundedIcon />} label= {park.bins} />
          </Stack>

          <PresentDogs activePark={park}/>
        </CardContent>
        <CardActions>
          {showCheckIn && <Button
            sx={{color: 'white' }}
            variant="contained"
            color="secondary"
            onClick={() => {
              navigate(`/parken/${park.slug}/inchecken`);
            }}
          >Inchecken</Button> }
        </CardActions>
      </CardActionArea>
    </Card>
  );
};

export default ParkCard;