import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { FormLabel } from '@mui/material';
import Container from '@mui/material/Container';
import TextField from '@mui/material/TextField';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Button from '@mui/material/Button';
import InputAdornment from '@mui/material/InputAdornment';
//import Skeleton from '@mui/material/Skeleton';
import { useForm } from 'react-hook-form';
import { ReactSVG } from 'react-svg';

import CheckInDetail from './CheckInDetail';
import { Capitalize } from '../../helpers/strings';
import IconDog from '../../assets/img/icons/icon-dog.svg';
import IconOwner from '../../assets/img/icons/icon-owner.svg';
import IconSmallDog from '../../assets/img/icons/icon-small-dog.svg';
import IconMediumDog from '../../assets/img/icons/icon-medium-dog.svg';
import IconLargeDog from '../../assets/img/icons/icon-large-dog.svg';



const CheckIn = () => {
  let { slug } = useParams();
  const {
    handleSubmit,
    formState: { errors }
  } = useForm();
  const [park, setPark] = useState({});
  const [dogName, setDogName] = useState();
  const [owner, setOwner] = useState();
  const [dogSize, setDogSize] = useState('left');
  const [breed, setBreed] = useState();
  const [duration, setDuration] = useState('web');
  const [submitted, setSubmitted] = useState(false);

  useEffect(() => {
    fetchPark();
  }, []);

  const fetchPark = () => {
    fetch(
      `/api/parks/${slug}`,
    )
      .then(res => res.json())
      .then(data => {
        console.log(data);
        setPark(data);
      })
      .catch(error => console.log(error));
  };

  const handleDogSize = (event, newDogSize) => {
    setDogSize(newDogSize);
  };

  const handleDuration = (event, newDuration) => {
    setDuration(newDuration);
  };

  const onSubmit = () => {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        owner,
        dogName,
        dogSize,
        breed,
        duration,
      })
    };

    fetch(
      `/api/parks/${slug}/check-in`,
      requestOptions,
    )
      .then(res => res.json())
      .then(data => {
        setSubmitted(true);
        return data;
      })
      .catch(error => console.log(error));
  };

  return (
    <>
      <CssBaseline />
      {submitted && <CheckInDetail dogName={dogName}/>}
      {!submitted && <form onSubmit={handleSubmit(onSubmit)}>
        <Container sx={{ marginTop: 2 }} maxWidth="md">
          <Typography variant="h4" component="div" gutterBottom>
          Inchecken in {Capitalize(park.name || '')}
          </Typography>
          <Typography variant="p" component="div" gutterBottom>
          Hier kan je met je hondje samen inchecken.
          </Typography>
          <Box
            component="form"
            sx={{
            }}
            noValidate
            autoComplete="off"
          >
            <Box sx={{ display: 'flex', alignItems: 'flex-end', paddingTop: 2 }}>
              <TextField
                id="input-with-icon-textfield"
                label="Hondje"
                variant="outlined"
                fullWidth
                onChange={(e) => {
                  setDogName(e.target.value);
                }}
                helperText="Enter your name and press [Enter]"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <ReactSVG src={IconDog} />
                    </InputAdornment>
                  ),
                }}
              />
              {errors.dogName && <span>This field is required</span>}
            </Box>

            <Box sx={{ display: 'flex', alignItems: 'flex-end', paddingTop: 4 }}>
              <TextField id="input-with-sx"
                label="Baasje"
                variant="outlined"
                fullWidth
                onChange={(e) => {
                  setOwner(e.target.value);
                }}
                helperText="Enter your name and press [Enter]"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <ReactSVG src={IconOwner} />
                    </InputAdornment>
                  ),
                }} />
              {errors.owner && <span>This field is required</span>}
            </Box>

            <Box sx={{ marginTop: 4 }}>
              <FormLabel>Type</FormLabel>

              <ToggleButtonGroup
                value={dogSize}
                fullWidth
                color="primary"
                exclusive
                sx={{ maxWidth: '100%',  height: '6rem' }}
                onChange={handleDogSize}
                size="small"
                aria-label="Type"
              >
                <ToggleButton value="small" aria-label="left aligned">
                  <img src={IconSmallDog} style={{ width: '100%', height: '100%' }} />
                </ToggleButton>
                <ToggleButton value="medium" aria-label="centered">
                  <img src={IconMediumDog} style={{ width: '100%', height: '100%' }} />
                </ToggleButton>
                <ToggleButton value="large" aria-label="right aligned">
                  <img src={IconLargeDog} style={{ width: '100%', height: '100%' }} />
                </ToggleButton>
              </ToggleButtonGroup>
            </Box>

            <Box sx={{ display: 'flex', alignItems: 'flex-end', paddingTop: 4 }}>
              <TextField
                id="standard-basic"
                label="Ras (optioneel)"
                onChange={(e) => {
                  setBreed(e.target.value);
                }}
                variant="standard"
                fullWidth />
            </Box>

            <Box sx={{ paddingTop: 4 }}>
              <FormLabel>Duurtijd</FormLabel>

              <ToggleButtonGroup
                fullWidth
                aria-label="Duur bezoek:"
                color="primary"
                value={duration}
                exclusive
                onChange={handleDuration}
                sx={{ 'justifyContent': 'space-between' }}
              >
                <ToggleButton size="medium" value="39">30 MIN</ToggleButton>
                <ToggleButton size="medium" value="60">60 MIN</ToggleButton>
              </ToggleButtonGroup>
            </Box>

            <Box sx={{ paddingTop: 4 }}>
              <Button
                sx={{color: 'white' }}
                variant="contained"
                color="secondary"
                onClick={onSubmit}
                fullWidth>Inchecken</Button>
            </Box>
          </Box>
        </Container>
      </form>}
    </>
  );
};

export default CheckIn;