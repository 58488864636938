
import { useEffect, useState, useRef } from 'react';
//import L from 'leaflet';
import { MapContainer, TileLayer, ZoomControl } from 'react-leaflet';
import MarkerClusterGroup from 'react-leaflet-cluster';
import Control from 'react-leaflet-custom-control';

import { styled } from '@mui/system';
import ModalUnstyled from '@mui/base/ModalUnstyled';
import useSize from '@react-hook/size';
//import ViewListIcon from '@mui/icons-material/ViewList';
//import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import SearchIcon from '@mui/icons-material/Search';
import Autocomplete from '@mui/material/Autocomplete';

import Marker from './Marker';
import ParkDetail from '../ParkDetail';
import SwipeableEdgeDrawer from '../ParkDetail/ParkDetailMobile';
import { Capitalize } from '../../helpers/strings';

import './Map.css';

export const DEFAULT_CENTER = [51.202581289951, 4.4329833984375];
export const BASE_LAYER = 'https://server.arcgisonline.com/ArcGIS/rest/services/Canvas/World_Light_Gray_Base/MapServer/tile/{z}/{y}/{x}';
export const ANTWERP_LAYER = 'https://tiles.arcgis.com/tiles/1KSVSmnHT2Lw9ea6/arcgis/rest/services/basemap_stadsplan_v6/MapServer/tile/{z}/{y}/{x}';
//export const SATELLITE_LAYER = 'http://tile.informatievlaanderen.be/ws/raadpleegdiensten/tms/1.0.0/omwrgbmrvl@GoogleMapsVL/{z}/{x}/{y}.png';
export const DEFAULT_ZOOM = 13;
//export const MIN_ZOOM = 11;
//export const MAX_ZOOM = 19;
//import MapMarkerIcon from '../../assets/img/icons/icon-avatar-dog-default.svg';

const StyledModal = styled(ModalUnstyled)`
  position: fixed;
  z-index: 1300;
  min-width: 200px;
  right: 1rem;
  bottom: 0;
  top: 5rem;
  display: flex;
  align-items: right;
  justify-content: right;
`;

/* const createClusterCustomIcon = function () {
  return L.divIcon({
    html: <div><ReactSVG src={MapMarkerIcon} /></div>,
    className: 'marker-cluster-custom',
    iconSize: L.point(40, 40, true),
  });
}; */

const Map = () => {
  const target = useRef(null);
  const [width] = useSize(target);
  const isMobile = width < 720;
  const [parks, setParks] = useState([]);
  const [map, setMap] = useState(null);
  const [open, setOpen] = useState(false);
  //const [view, setView] = useState('map');
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [activePark, setActivePark] = useState(null);

  useEffect(() => {
    fetchAllParks();
  }, []);

  const fetchAllParks = () => {
    fetch(
      '/api/parks',
    )
      .then(res => res.json())
      .then(data => {
        setParks(data);
      })
      .catch(error => console.log(error));
  };

  const onClickMarker = (park) => {
    handleOpen();
    setActivePark(park);

    map.setView([
      park.coordinates[1],
      park.coordinates[0]
    ], 16);
  };

  const formattedParks = parks.map((park) => {
    return {
      label: Capitalize(park.name),
      value: park.name,
    };
  }).sort((a, b) => a.label > b.label ? 1 : -1);

  const onClickAutoComplete = (e) => {
    const parkName = e.target.innerText.toLowerCase();
    const foundPark = parks.find(park => park.name === parkName);
    handleOpen(foundPark);
    setActivePark(foundPark);

    map.setView([
      foundPark.coordinates[1],
      foundPark.coordinates[0]
    ], 16);
  };

  return (
    <div ref={target}>
      <Autocomplete
        //disablePortal
        //freeSolo
        fullWidth
        onChange={(e) => onClickAutoComplete(e)}
        id="combo-box-demo"
        clearIcon={<SearchIcon fontSize="small" />}
        options={formattedParks}
        sx={{ maxWidth: '300px', background: 'white', position: 'absolute', top: '80px', zIndex: 1100, left: '20px', right: '20px', marginLeft: 'auto', marginRight: 'auto' }}
        renderInput={(params) => <TextField sx={{ textTransform: 'capitalize' }} {...params} label="Zoek jouw hondenpark" />}
      />
      <MapContainer center={DEFAULT_CENTER} zoom={DEFAULT_ZOOM} maxZoom={30} scrollWheelZoom={true} zoomControl={false} whenCreated={setMap}>
        <TileLayer url={BASE_LAYER} />
        <TileLayer url={ANTWERP_LAYER} />
        <ZoomControl position="bottomleft" />
        <Control position='bottomleft'>
          {/*    <Button color='inherit' onClick={() => {
            setView(view === 'map' ? 'list' : 'map');
          }}>
            <ViewListIcon />
          </Button> */}
        </Control>
        <MarkerClusterGroup
          //iconCreateFunction={createClusterCustomIcon}
          //maxClusterRadius={150}
          //spiderfyOnMaxZoom={true}
          polygonOptions={{
            fillColor: '#ffffff',
            color: '#f00800',
            weight: 5,
            opacity: 1,
            fillOpacity: 0.8,
          }}
          showCoverageOnHover
          zoomToBoundsOnClick
          animateAddingMarkers
          chunkedLoading>
          {parks.map(park => (
            <Marker
              key={park.name}
              park={park}
              activePark={activePark}
              onClick={onClickMarker}
            />
          ))}
        </MarkerClusterGroup>
        {(activePark && !isMobile) &&
            <StyledModal
              aria-labelledby="unstyled-modal-title"
              aria-describedby="unstyled-modal-description"
              open={open}
              disablePortal={false}
              onClose={handleClose}
            >
              <ParkDetail key={activePark.name} park={activePark} />
            </StyledModal>
        }
        {(activePark && isMobile) &&
            <SwipeableEdgeDrawer key={activePark.name} park={activePark} />
        }
      </MapContainer>
    </div>
  );
};

export default Map;