import {
  Outlet,
} from 'react-router-dom';
import Toolbar from '@mui/material/Toolbar';
import { createTheme, ThemeProvider} from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

import Topbar from '../Topbar';
//import Footer from '../Footer';

import './Layout.css';

const theme = createTheme({
  typography: {
    fontFamily: [
      'San Francisco',
      '-apple-system',
      'BlinkMacSystemFont',
      'sans-serif'
    ].join(','),
  },
  palette: {
    primary: {
      main:'#042856',
    },
    secondary: {
      main: '#DAA900',
    },
    grey: {
      main: '#dddddd',
    },
  },
});

const Layout = () => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Topbar />
      <Toolbar />
      <div className="content">
        <Outlet />
      </div>
      {/*       <Footer />
 */}    </ThemeProvider>
  );
};

export default Layout;