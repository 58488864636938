import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Alert from '@mui/material/Alert';
import Grid from '@mui/material/Grid';

import ParkCard from '../ParkDetail/ParkCard';

const CheckInDetail = ({ dogName }) => {
  let { slug } = useParams();

  const [park, setPark] = useState();
  useEffect(() => {
    fetchPark();
  }, []);

  const fetchPark = () => {
    fetch(
      `/api/parks/${slug}`,
    )
      .then(res => res.json())
      .then(data => {
        console.log(data);
        setPark(data);
      })
      .catch(error => console.log(error));
  };

  if (!park) return null;

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <Alert mt={2} severity="success">{dogName} is nu ingecheckt in de weide!</Alert>
          <ParkCard park={park} />
        </Grid>
      </Grid>
    </>
  );
};

export default CheckInDetail;
