import { useEffect, useState } from 'react';
import moment from 'moment';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';

//import IconDog from '../../assets/img/icons/icon-dog.svg';
//import IconAvatarPrimairy from '../../assets/img/icons/icon-avatar-dog-primairy.svg';
//import IconAvatarSecondairy from '../../assets/img/icons/icon-avatar-dog-secondairy.svg';
import IconAvatarDefault from '../../assets/img/icons/icon-avatar-dog-default.svg';

const PresentDogs = ({ activePark }) => {
  const [checkins, setCheckins] = useState([]);

  useEffect(() => {
    getCheckIns();
  }, []);

  const getCheckIns = () => {
    fetch(
      `/api/parks/${activePark.slug}/check-ins`,
    )
      .then(res => res.json())
      .then(data => {
        setCheckins(data);
      })
      .catch(error => console.log(error));
  };

  const presentDogs = checkins.map((item, i) => {
    return (
      <>
        <ListItem
          key={i}
          disablePadding
          secondaryAction={
            <Typography
              sx={{ marginTop: '1rem', marginLeft: '0.5rem', width: '100%' }}
              gutterBottom
              variant="span"
              component="div"
              edge="end">
              Van {moment(item.createdAt).format('HH:mm')}
            </Typography>
          }
          alignItems="flex-start"
        >
          <ListItemAvatar>
            <Avatar alt={item.dogName} src={IconAvatarDefault} sx={{ width: 37, height: 37 }} />
          </ListItemAvatar>
          <ListItemText primary={item.dogName} secondary={item.owner} />
        </ListItem>
        <Divider variant="inset" component="li" />
      </>
    );
  });

  return (
    <>
      <Box sx={{ display: 'flex', alignItems: 'flex-end', paddingTop: 2 }}>
        <Avatar sx={{ bgcolor: '#002856ff' }}>{checkins.length}</Avatar>
        <Typography sx={{ marginTop: '1rem', marginLeft: '0.5rem', width: '100%' }} gutterBottom variant="h6" component="div">
          Aanwezige hond(en)
        </Typography>
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'flex-end', paddingTop: 2 }}>
        <List sx={{ width: '100%' }}>
          {presentDogs}
        </List>
      </Box>
    </>
  );
};

export default PresentDogs;