import { Marker as LeafletMarker } from 'react-leaflet';
import { Icon } from 'leaflet';

//import marker from './marker.png';
import MapMarkerIcon from '../../assets/img/icons/icon-marker-hondje.svg';

export const DEFAULT_CENTER = [51.202581289951, 4.4329833984375];

const dogMarker = new Icon({
  iconUrl: MapMarkerIcon,
  iconSize: [50, 50]
});

const activeDogMarker = new Icon({
  iconUrl: MapMarkerIcon,
  iconSize: [70, 70]
});

const Marker = ({ park = {}, activePark, onClick}) => {
  let isActive = false;

  if (activePark) {
    isActive = park.name === activePark.name;
  }

  return (
    <LeafletMarker
      key={park.name}
      icon={isActive ? activeDogMarker : dogMarker}
      position={[
        park.coordinates[1],
        park.coordinates[0]
      ]}
      eventHandlers={{
        click: () => {
          onClick(park);
        },
      }} />
  );
};

export default Marker;