import { render } from 'react-dom';
import {
  BrowserRouter,
  Routes,
  Route,
} from 'react-router-dom';
import moment from 'moment-timezone';

import Layout from './components/Layout';
import Map from './components/Map';
import CheckIn from './components/CheckIn';

import './App.css';

const rootElement = document.getElementById('root');
moment.tz(moment.tz.guess()).zoneAbbr();

render(
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route index element={<Map />} />
        <Route path="/parken/:slug/inchecken" element={<CheckIn />} />
      </Route>
    </Routes>
  </BrowserRouter>,
  rootElement
);